import React from 'react'
import styled from 'styled-components'
import "../App.css"
import Grid from './DetailGrid'
import ReactGA from 'react-ga4';

function Home(){

  ReactGA.send({
    hitType: "pageview",
    page: "/",
    title:"Home"
  })
    return (
      <HomeStyle className="home">
        <Grid/>
        <footer className="foo-styles">
          &nbsp;
          <h3>Connect With Me</h3>
          <ul>
            <a href="https://github.com/masnoonJunaid/" rel="nopener noreferrer" target="_blank">
            <img className="social-img" alt="Github Profile" src="./images/gith.svg"/>
            </a>
            <a rel="nopener noreferrer" target="_blank" href="https://www.linkedin.com/in/masnoon-junaid/">
              <img className="social-img" alt=" Masnoon Linkedin Profile" src="./images/linkedin.svg"/>
            </a>
            {/* <a rel ="nopener noreferrer" target="_blank"href="https://www.instagram.com/masnoonjunaid/">
              <img className="social-img" alt="Masnoon Instagram" src="./images/insta.svg"/>
            </a> */}
            <a rel ="nopener noreferrer" target="_blank"href="https://twitter.com/MasnoonJunaid">
              <img className="social-img" alt="Masnoon twitter" src="./images/twitter.svg"/>
            </a>
            <a rel ="nopener noreferrer" target="_blank"href="https://campusassembly.com">
                <img className="social-img" alt="Campus Assembly" src="./images/Camass-more-big.svg"/>
            </a>
          </ul>
           <h5 className="react"> with <span className="unicode">♥</span> react framework</h5>
           
          <p><b>© 2021-2024</b></p>
        </footer>
        </HomeStyle>
    )
  }




const HomeStyle = styled.div`
*{
  margin:0;
  padding:0;
}

h1{
  color:#2510D7;
  margin-left:10%;
  font-family:Roboto;
}

.search-input{
  margin-left:10%;
  height:5vh;
  border:none;
  text-align:center;
  width:40%;
}
.line{
  width:40%;
  margin-left:10%;
}
.header{
  width:100%;
}

.search-input:focus{
  focus:none;
}

.social{
  margin-top:30%;
  float:right;
  margin-right:10%;
}

.social-img{
  width:31px;
  object-fit:cover;
  cursor: pointer;
  height:31px;
  margin:2%;


}

.social-img:hover{
  transform:scale(1.2);
}

.unicode{
  color:hotpink;
}

.foo-styles{
  text-align:center;
  background-color:#DFEBEB;
}

.campusAssembly{
  display:flex;
  align-items:center;
  justify-content:center;
  text-decoration:none;
  height:4vh;
}


`

export default Home
