import styled from "styled-components"
import React from 'react'

function Grid(){
  return (
    <GridStyle>
      <h1 className="name-head">Masnoon Junaid</h1>
      <article className="art-style">
        <div className="text-div">
          <h2>Computer Science And Engineering</h2>
          <p>Equipped with a Bachelor's degree in Computer Science and Engineering from Aliah University, Government of West Bengal, Kolkata, India</p>
        </div>
        <img className="icons-svg" alt="" src="./gridImages/cse.svg"/>
      </article>
      <article className="art-style">
        <div className="text-div">
          <h2>Building Software Products</h2>
          <p>Adapting new challenges quickly and expanding my skill set, Learning and building responsive web, android, ios applications with reactjs, react-native, flutter, html, css, javaScript, etc</p>
        </div>
        <img className="icons-svg" alt="" src="./gridImages/logo22.svg"/>
      </article>
      <article className="art-style" id="practice">
        <div className="text-div">
          {/* <h2>Practice And Participation</h2> */}
          <h2>Working experience in Startups</h2>
          <p>Experienced working under senior leadership in a small focused team, meeting requirements with effective coordination and communication of all stakeholders </p>
        </div>
        <img className="icons-svg" alt="" src="./gridImages/experience.svg"/>
      </article>
      <article className="art-style">
        <div className="text-div">
          <h2>From Ideation to Execution</h2>
          <p>Transforming ideas into innovative solutions, my journey involves hands-on experience in every phase of software development – from conceptualization to seamless execution</p>
        </div>
        <img className="icons-svg" alt="" src="./gridImages/server.svg"/>
      </article>
      <article className="art-style">
        <div className="text-div">
          <h2>Navigate for new startups and Ideas</h2>
          <p>Techcrunch, MIT technology review articles to get insight of tech trends, funding and Ideas</p>
        </div>
        <img className="icons-svg" alt="" src="./gridImages/ideas.svg"/>
      </article>
      <article className="art-style">
        <div className="text-div">
          <h2>Me as a team member</h2>
           <p> Coordinating in perfect harmony to attain common goals, not afraid to take lead on projects, and I can do so with little guidance</p>
        </div>
        <img className="icons-svg" alt="" src="./gridImages/improve.svg"/>
      </article>
    </GridStyle>
  )
}

const GridStyle = styled.div`
*{
  display:grid;
  float:left;
  width:80%;
  text-align:left;
  padding:0;
}

.text-div{
  max-width:100%;
  max-height:100%;
}

h2, p{
  font-family: 'DM Sans', sans-serif;
  margin: auto;
}
.art-style{
  width:50%;
  height:32vh;
  max-height:100%;
  float:left;
  display:flex;
  flex-wrap:nowrap;
  margin-left:auto;
  margin-right:auto;
  text-align:center;
  background-color:#DFEBEB;
  box-shadow: 1px 0 1px 0;
}

@media screen and (max-width:1000px){
  .art-style{
    float:left;
    width:100%;
    height:50vh;
    display:flex;
    flex-wrap:nowrap;
    margin-left:auto;
    margin-right:auto;
    text-align:center;
  }
  *{
    float:none;
    width:100%;
    display:block;
  }
  .icons-svg{
    width:80%;
    height:80%;
  }
}

#practice{
  background-color:#FCF5F2;
}
#contact{
  background-color:#FCF5F2;
}

.icons-svg{
  width:40%;
  width:40%;
}

@media screen and (max-width:600px){
  .icons-svg{
    z-index:1000;
    margin-right:4%;
    opacity:0.8;
    margin-top:6%;
  }
  p{
    font-weight:16px;
    text-align:left;
    width:90%;
    align-item:center;
    justify-content:center;
    margin-top:6%
  }
  h2{
    font-weight:22px;
    text-align:left;
    width:90%;
    align-item:center;
    justify-content:center;
    margin-top:20%
  }
  .art-style, #practice{
    background-color:#FFFFFF;
  }


}

.name-head {
  font-family: 'DM Sans',sans-serif;
    text-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: black;
    font-size: 2rem;
    line-height: 45px;
    height: 35px;
    display:none;
}

@media screen and (min-width:700px){ 
  .name-head {
    display:none
  }
}

`


export default Grid
