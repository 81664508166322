import React from "react"
import styled from 'styled-components'
import "../App.css"


class SelectedUi extends React.Component {
  render(){
    const {title,github,icon,details } = this.props.repo;
    return (
            <ListStyle className="selected-list">
              <img className="side-avatar" alt="" src={icon}/>
              <div className="sec-1">
                <h3 className="player-name">{title}</h3>
                <div className="sec-2">
                  <p>Details</p>
                </div>
              </div>
              <div className="sec-3">
                <a href={github} rel="nopener noreferrer" target="_blank">
                <img className="icon"alt="" src="images/gith.svg"/>
                </a>
              </div>
            </ListStyle>
    )

  }
}


const ListStyle = styled.li`
*{
  flex-wrap:wrap;
  display:flex;
}

.side-avatar{
  width:5vw;
  margin-left:5%;
  height:10vh;
  border-radius:16px;
}


.sec-1{
  display:block;
  width:100%;
  align-items:center;
  margin:4%;
}

p{
  margin-top:10%;
}
.sec-2{
  display:flex;
  flex-wrap:nowrap;
  margin-bottom:2vh;
  width:100%;
  height:6vh;
  align-items:center;
  margin-left:3%;
}

.sec-3{
  display:flex;
  flex-wrap:nowrap;
  float:right;
  margin-right:2%;
  align-items:center;

}
.icon{
  width:25px;
  height:25px;
}

.icon:hover{
    transform:scale(1.2);
}
.player-name{
  text-align:center;
  height:1vh;
  display:flex;
  flex-wrap:nowrap;
  font-family:'DM Sans', sans-serif;
  width:100%;
}

  `


export default SelectedUi
