import  React, {  Component } from 'react'
import { connect,repo} from './Data';

const DataContext = React.createContext() ;
class DataProvider extends Component {
  state = {
    products: [],
    contacts: [],
    repos:[]
  };
  componentDidMount() {
    this.setProducts();
  }
  setProducts = () => {
    let gitData = [];
    repo.forEach((item) => {
      const singleRepo = {...item};
      gitData = [...gitData, singleRepo]
    });

    let tempData = [];
    connect.forEach(item => {
      const singleItem = {...item};
      tempData = [...tempData, singleItem];
    });

    this.setState(()=> {
      return {repos:gitData,contacts:tempData};
    });
  };

  render(){
    return(
      <DataContext.Provider value={{
        ...this.state,
      }}
      >
        {this.props.children}
      </DataContext.Provider>
    )
  }
}

const DataConsumer = DataContext.Consumer;

export {  DataProvider, DataConsumer};
