export const connect = [
  {
    id:1,
    title:"Instagram",
    img: "images/insta.svg",
    link:"https://www.instagram.com/masnoonjunaid/",
  },
  {
    id:3,
    title : "Github",
    img : "images/github-connect.svg",
    link:"https://github.com/masnoonJunaid",
  },
  {
    id:4,
    title : "Linkedin",
    img : "images/linkedin.svg",
    link:"https://www.linkedin.com/in/masnoon-junaid/",
  }
]

export const repo = [
  {
    id: 1,
    title:"Recipe App",
    github:"https://github.com/masnoonJunaid/recipe",
    icon:"gitImages/recipe.svg",
    details:""
  },
  {
    id: 2,
    title:"Responsive-test-2.0",
    github:"https://github.com/masnoonJunaid/Responsive-test-2.0",
    icon:"gitImages/responsive.svg",
    details:""
  },
  {
    id: 3,
    title:"Bets",
    github:"https://github.com/masnoonJunaid/bets",
    icon:"gitImages/bets.svg",
    details:""
  },
  {
    id: 4,
    title:"User Group",
    github:"https://github.com/masnoonJunaid/usergroup",
    icon:"gitImages/user.svg",
    details:""
  },
  {
    id: 5,
    title:"Memory Game",
    github:"https://github.com/masnoonJunaid/udacity-memorygame",
    icon:"gitImages/memory.svg",
    details:""
  },
  {
    id: 6,
    title: "MyReads App",
    github:"https://github.com/masnoonJunaid/MyReads-book-tracking",
    icon: "gitImages/myreads.svg",
    details:""
  },
  {
    id: 7,
    title:"React-Portfolio",
    github:"https://github.com/masnoonJunaid/react-portfolio",
    icon:"gitImages/portfolio.svg",
    details:""
  },
  {
    id: 8,
    title:"BeerApp",
    github:"https://github.com/masnoonJunaid/scrimba-beerApp",
    icon:"gitImages/beer-app.svg",
    details:""
  },
  {
    id: 9,
    title:"Resume-challenge",
    github:"https://github.com/masnoonJunaid/resume-challenge",
    icon:"gitImages/resume.svg",
    details:"Replicate your resume"
  }
]
