import React from 'react';
import './App.css';
import {  Switch,Route } from 'react-router-dom'
import Home from "./components/Home";
import Status from "./components/Status"
import Sidebar from "./components/Sidebar"
import ReactGA from 'react-ga4'

class App extends React.Component {
  render(){
    ReactGA.initialize('G-W4S0WZF5SD');
    return (
      <React.Fragment>
        <Sidebar/>
        <Switch>
          <Route exact path="/" component = {Home}/>
          <Route path="/status+goal+current projects+university_life" component={Status}/>
        </Switch>
      </React.Fragment>
    );
  }
}

export default App;
