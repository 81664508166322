import React from 'react'
import styled from "styled-components"
import SelectedUi from './SelectedUi.js'
import { DataConsumer } from '../context';
import "../App.css"


function Sidebar() {
  return (
    <ButtonStyle className="sidebar">
      <div className="prof-img">
        <h1 id="h1-name">Masnoon Junaid</h1>
      </div>
      <h2>Practice Projects</h2>
      <hr className="h-line"/>
      <div className="sidebar-list">
        <DataConsumer>
          {value => {
            return value.repos.map(repo=>{
              return <SelectedUi key={repo.id} repo = {repo}/>
            })
          }}
        </DataConsumer>

      </div>
      <a className='github-link' href="https://github.com/masnoonJunaid">
        <button className='github-button'>All Repository</button>
      </a>
    </ButtonStyle>
  )
}


const ButtonStyle = styled.div`
.prof-img{
  width:100%;
  height:28.2vh;
  align-items:center;  
  justify-content:center;
  text-align:center;
  place-content:center
}

.github-link {
  cursor: pointer;
  width:70% !important;
  display: block;
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1rem;
}

.github-button {
 
  hei
}

#h1-name{
  font-family: 'DM Sans', sans-serif;
  // margin-left:auto;
  // margin-right:auto;
  text-align:center;
  // position:static;
  align-items:center;
  // color:#61b6b3;
  color:black;
  font-size:2rem;
  line-height:45px;
  height:35px;
  position:absolute;
  // margin-top:8vh;
  margin-left:3.6vw;
}


button{
  cursor: pointer;
  width:15rem;
  height:6vh;
  // background-color:#2510D7;
  text-decoration:none;
  background-color:black;
  border-radius:7px;
  margin-left:3%;
  color:ghostwhite;
  border:none;
  display:block;
}

button:hover{
  background-color:#61b6b3;
}

.h-line{
  // width:90%;
  color:#2510D7;
}

h2{
  font-family:'DM Sans', sans-serif;
  margin-left:4%;
}
`

export default Sidebar
